import React from "react"

import { Link } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { ClassNameValue } from "tailwind-merge/dist/lib/tw-join"

export type FooterProps = {
	className?: ClassNameValue
}

const Footer: React.FC<FooterProps> = ({ className }) => {
	return (
		<footer
			className={twMerge(
				"flex flex-col bg-gray-2 px-6 sm:px-12 py-8 text-xs lg:px-44",
				className
			)}
		>
			<div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2">
				<div className="flex flex-col md:text-start">
					<span className="text-base font-bold">Alabama Legislative Services Agency</span>
					<span className="mt-1">
						Alabama State House
						<br />
						11 South Union Street
						<br />
						Montgomery, AL 36130
					</span>
					<a className="mt-3 underline" href="tel:334-261-0600">
						(334) 261-0600
					</a>
				</div>
				<div className="flex items-start gap-8 md:justify-end md:gap-14 md:pt-[0.09375rem] xl:gap-24">
					<Link className="underline" to="/">
						Home
					</Link>
					<Link className="underline" to="/resources-and-announcements">
						Resources and Announcements
					</Link>
				</div>
			</div>
			<hr className="mx-24 my-6 text-gray-3" />
			<span className="md:text-center mb-5 md:text-balance">
				The Alabama Legislative Services Agency serves as the official publisher of the
				Alabama Administrative Code and Administrative Monthly. Please direct questions
				concerning the application or enforcement of the rules of a specific licensing
				board or state agency rules to that licensing board or state agency.
			</span>
			<span className="md:text-center">
				Copyright © Alabama Legislative Services Agency. All rights reserved.
			</span>
		</footer>
	)
}

export default Footer
