/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"

import * as types from "./graphql"

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
	"\n\tquery search(\n\t\t$query: String!\n\t\t$agencyControlNumbers: [String!]\n\t\t$chapterIdTexts: [String!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearch(\n\t\t\tquery: $query\n\t\t\tagencyControlNumbers: $agencyControlNumbers\n\t\t\tchapterIdTexts: $chapterIdTexts\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\n\t\t\t\t... on ChapterResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t}\n\n\t\t\t\t... on RuleResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t\tdescription\n\t\t\t\t\tauthor\n\t\t\t\t\teditorsNote\n\t\t\t\t}\n\n\t\t\t\t... on AgencyResultItem {\n\t\t\t\t\tcontrolNumber\n\t\t\t\t}\n\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n":
		types.SearchDocument,
	"\n\tfragment adminCodeSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t\t__typename\n\t\t\t}\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.AdminCodeSearch_QueryFragmentFragmentDoc,
	"\n\tfragment announcementList_QueryFragment on Query {\n\t\tannouncements {\n\t\t\ttitle\n\t\t\tupdatedAt\n\t\t\tdescription\n\t\t}\n\t}\n":
		types.AnnouncementList_QueryFragmentFragmentDoc,
	"\n\tfragment filingHistory_FilingsFragment on Filing {\n\t\tname\n\t\tscope\n\t\tfilingTypeKey\n\t\tintendedAction\n\t\tcurrentCodeNumber\n\t\teffectiveDate\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tid\n\t\t\tfileUrl\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\t}\n":
		types.FilingHistory_FilingsFragmentFragmentDoc,
	"\n\tfragment filingRow_Filing on Filing {\n\t\tid\n\t\tname\n\n\t\tintendedAction\n\t\tscope\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\n\t\tagency {\n\t\t\ttitle\n\t\t}\n\n\t\tfilingType {\n\t\t\tname\n\t\t}\n\n\t\tfilingTypeKey\n\n\t\t# ... on Filing @defer {\n\t\t...processForms_FilingFragment\n\n\t\tstatus\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tfileUrl\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tfileUrl\n\n\t\t\tsubmissionDate\n\t\t\tresubmissionDate\n\t\t\tisProposal\n\t\t\tstrikethroughUrl\n\n\t\t\trejectionComments {\n\t\t\t\t__typename\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tsubmissionDeadline\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tvalues {\n\t\t\t\t\tdate\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tfilingType {\n\t\t\thasProposal\n\t\t\tcanBeWithdrawnOrDisapproved\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t\t# }\n\n\t\tactivityLog {\n\t\t\t__typename\n\t\t}\n\n\t\teffectiveDate @include(if: $showEffectiveDate)\n\t}\n":
		types.FilingRow_FilingFragmentDoc,
	"\n\tmutation deleteFiling($id: ID!) {\n\t\tpermanentlyDeleteFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DeleteFilingDocument,
	"\n\tmutation submitFiling($id: ID!) {\n\t\tsubmitFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n":
		types.SubmitFilingDocument,
	"\n\tmutation recallFiling($id: ID!) {\n\t\trecallFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n":
		types.RecallFilingDocument,
	"\n\tmutation approveFiling($id: ID!) {\n\t\tapproveFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.ApproveFilingDocument,
	"\n\tmutation withdrawOrDisapproveFiling($id: ID!, $action: FilingUserAction!) {\n\t\twithdrawOrDisapproveFiling(id: $id, action: $action) {\n\t\t\tstatus\n\t\t}\n\t}\n":
		types.WithdrawOrDisapproveFilingDocument,
	"\n\tmutation rejectFiling($id: ID!, $comment: String!) {\n\t\trejectFiling(id: $id, comment: $comment) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.RejectFilingDocument,
	"\n\tmutation logout {\n\t\tlogout\n\t}\n": types.LogoutDocument,
	"\n\tmutation addAgency($controlNumber: String!, $title: String!) {\n\t\taddAgency(controlNumber: $controlNumber, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.AddAgencyDocument,
	"\n\tmutation addAnnouncement($title: String!, $description: String!, $content: String!) {\n\t\taddAnnouncement(title: $title, description: $description, content: $content) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.AddAnnouncementDocument,
	"\n\tmutation addChapter($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddChapter(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.AddChapterDocument,
	"\n\tmutation addDraftFiling(\n\t\t$agencyID: ID!\n\t\t$filingTypeKey: String!\n\t\t$name: String\n\t\t$scope: FilingScope\n\t\t$intendedAction: IntendedAction\n\t\t$currentCodeNumber: String\n\t) {\n\t\taddFiling(\n\t\t\tagencyID: $agencyID\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tintendedAction: $intendedAction\n\t\t\tname: $name\n\t\t\tscope: $scope\n\t\t\tcurrentCodeNumber: $currentCodeNumber\n\t\t) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.AddDraftFilingDocument,
	"\n\tfragment newDraftButton_QueryFragment on Query {\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t}\n":
		types.NewDraftButton_QueryFragmentFragmentDoc,
	"\n\tquery newDraftButton($agencyID: ID!, $needsChapter: Boolean!, $needsRules: Boolean!) {\n\t\tagency(id: $agencyID) {\n\t\t\tchapters {\n\t\t\t\t... on Chapter @include(if: $needsChapter) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\n\t\t\t\trules @include(if: $needsRules) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.NewDraftButtonDocument,
	"\n\tmutation addResource($title: String!, $file: File!) {\n\t\taddResource(title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.AddResourceDocument,
	"\n\tmutation addRule($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddRule(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.AddRuleDocument,
	"\n\tmutation addRuleDraft($parentID: ID!, $idText: String, $title: String) {\n\t\taddRuleDraft(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.AddRuleDraftDocument,
	"\n\tfragment publicationArchives_QueryFragment on Query {\n\t\tpublicationArchives(type: $type) {\n\t\t\tid\n\t\t\tpublicationDate\n\t\t}\n\t}\n":
		types.PublicationArchives_QueryFragmentFragmentDoc,
	"\n\tquery searchFilings(\n\t\t$query: String!\n\t\t$controlNumbers: [String!]\n\t\t$years: [Int!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearchFilings(\n\t\t\tquery: $query\n\t\t\tcontrolNumbers: $controlNumbers\n\t\t\tyears: $years\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontent\n\t\t\t\tpublicationDate\n\t\t\t\tfileUrl\n\t\t\t\t__typename\n\n\t\t\t\t... on HistoricalFilingResultItem {\n\t\t\t\t\tpublicationType\n\t\t\t\t}\n\n\t\t\t\t... on FilingResultItem {\n\t\t\t\t\tisProposal\n\t\t\t\t\tfilingType\n\t\t\t\t\twithdrawDate\n\t\t\t\t\tdisapproveDate\n\t\t\t\t\tdisapproveWithSuggestionsDate\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n":
		types.SearchFilingsDocument,
	"\n\tfragment publicationArchiveSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.PublicationArchiveSearch_QueryFragmentFragmentDoc,
	"\n\tmutation repealRuleDraft($id: ID!, $title: String!, $description: String!) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tuseManualHistoryUpdate: false\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.RepealRuleDraftDocument,
	"\n\tfragment repealRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t}\n":
		types.RepealRuleDraftButton_RuleDraftFragmentFragmentDoc,
	"\n\tfragment resourceList_QueryFragment on Query {\n\t\tresources {\n\t\t\ttitle\n\t\t\tfileUrl\n\t\t}\n\t}\n":
		types.ResourceList_QueryFragmentFragmentDoc,
	"\n\tmutation signFormEntry(\n\t\t$filingID: ID!\n\t\t$formKey: String!\n\t\t$value: String!\n\t\t$screenshot: File!\n\t) {\n\t\tsignFormEntry(\n\t\t\tfilingID: $filingID\n\t\t\tformKey: $formKey\n\n\t\t\tvalue: $value\n\t\t\tscreenshot: $screenshot\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.SignFormEntryDocument,
	"\n\tmutation updateAnnouncement(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$description: String\n\t\t$content: String\n\t) {\n\t\tupdateAnnouncement(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tcontent: $content\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateAnnouncementDocument,
	"\n\tmutation updateResource($id: ID!, $title: String!, $file: File) {\n\t\tupdateResource(id: $id, title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateResourceDocument,
	"\n\tquery users {\n\t\tusers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n":
		types.UsersDocument,
	"\n\tfragment viewChapterButton_ChapterFragment on Chapter {\n\t\tidText\n\t\tfileUrl\n\t\tshowToPublic\n\t}\n":
		types.ViewChapterButton_ChapterFragmentFragmentDoc,
	"\n\tfragment viewCurrentFilings_PublicationFragment on Publication {\n\t\tid\n\t\ttitle\n\t\tpublicationDate\n\t\teditorsNote\n\t}\n":
		types.ViewCurrentFilings_PublicationFragmentFragmentDoc,
	'\n\tfragment viewCurrentFilings_FilingFragment on Filing {\n\t\tfilingType {\n\t\t\tkey\n\t\t\tname\n\n\t\t\thasProposal\n\t\t\tshouldMergeIntoCurrentCode\n\t\t}\n\n\t\tscope\n\t\tintendedAction\n\n\t\tagency {\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\theadingForNonstandardSectionInAam\n\t\t}\n\n\t\tproposal {\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tfileUrl\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\tcertification {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\ttitleFromFormEntry: formAnswer(formKey: "misc-notice", question: "title") {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\trecertifications {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\teffectiveDate\n\t\tisEffective\n\t}\n':
		types.ViewCurrentFilings_FilingFragmentFragmentDoc,
	"\n\tfragment viewRuleButton_RuleFragment on Rule {\n\t\tidText\n\t\tfileUrl\n\n\t\t...viewRuleButton_DeferredRuleFragment # @defer\n\t}\n":
		types.ViewRuleButton_RuleFragmentFragmentDoc,
	"\n\tfragment viewRuleButton_DeferredRuleFragment on Rule {\n\t\ttitle\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n":
		types.ViewRuleButton_DeferredRuleFragmentFragmentDoc,
	"\n\tfragment viewRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t\tidText\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t}\n":
		types.ViewRuleDraftButton_RuleDraftFragmentFragmentDoc,
	"\n\tquery user {\n\t\tuser {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n":
		types.UserDocument,
	"\n\tquery agencySortTitles {\n\t\tagencies(onlyShown: true) {\n\t\t\tsortTitle\n\t\t\ttitle\n\t\t\tcontrolNumber\n\t\t\tshown\n\t\t}\n\t}\n":
		types.AgencySortTitlesDocument,
	"\n\tquery announcementByTitle($title: String!) {\n\t\tannouncement(title: $title) {\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n":
		types.AnnouncementByTitleDocument,
	'\n\tfragment chapterDraftEditor_FilingFragment on Filing {\n\t\t...ruleDraftEditor_FilingFragment\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\t\tintendedAction\n\n\t\tagency {\n\t\t\tcontrolNumber\n\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\tid\n\n\t\t\t\t\tagencyTitle\n\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\t...viewRuleDraftButton_RuleDraftFragment\n\t\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment # @defer\n\t\t\t\t\t\t...repealRuleDraftButton_RuleDraftFragment # @defer\n\t\t\t\t\t\tid\n\n\t\t\t\t\t\tidText\n\t\t\t\t\t\ttitle\n\n\t\t\t\t\t\tisValid\n\n\t\t\t\t\t\tproposalCertification\n\t\t\t\t\t}\n\n\t\t\t\t\toverridePlacement\n\t\t\t\t\tplaceAt\n\t\t\t\t\tplaceAfterID\n\n\t\t\t\t\tproposalCertification\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on Chapter {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n':
		types.ChapterDraftEditor_FilingFragmentFragmentDoc,
	"\n\tmutation updateChapterDraft(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$ruleDrafts: [ID!]\n\t\t$ruleDraftsToSave: [RuleDraftToSave!]\n\t\t$overridePlacement: Boolean\n\t\t$placeAt: Placement\n\t\t$placeAfterID: ID\n\t\t$filingID: ID!\n\t) {\n\t\tupdateChapterDraft(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\truleDrafts: $ruleDrafts\n\t\t\truleDraftsToSave: $ruleDraftsToSave\n\t\t\toverridePlacement: $overridePlacement\n\t\t\tplaceAt: $placeAt\n\t\t\tplaceAfterID: $placeAfterID\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateChapterDraftDocument,
	"\n\tmutation deleteRuleDraft($id: ID!) {\n\t\tpermanentlyDeleteRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DeleteRuleDraftDocument,
	"\n\tmutation resetChapterDraft($id: ID!) {\n\t\tresetChapterDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.ResetChapterDraftDocument,
	"\n\tquery draftForEditor($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...chapterDraftEditor_FilingFragment\n\t\t\t...standaloneRuleDraftEditor_FilingFragment\n\n\t\t\tid\n\t\t\tname\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tdraft {\n\t\t\t\t\t__typename\n\t\t\t\t}\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.DraftForEditorDocument,
	'\n\tfragment ruleDraftEditor_FilingFragment on Filing {\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n':
		types.RuleDraftEditor_FilingFragmentFragmentDoc,
	"\n\tfragment ruleDraftEditor_RuleDraftFragment on RuleDraft {\n\t\t...repealRuleDraftButton_RuleDraftFragment\n\n\t\tid\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\tpenalty\n\n\t\tmanualHistoryUpdate\n\t\tuseManualHistoryUpdate\n\t\tgeneratedHistoryUpdate\n\n\t\tpageBreakBefore\n\t\tproposalCertification\n\n\t\tparent {\n\t\t\tidText\n\t\t}\n\n\t\tnewRule\n\t\texistingHistory\n\t}\n":
		types.RuleDraftEditor_RuleDraftFragmentFragmentDoc,
	'\n\tfragment standaloneRuleDraftEditor_FilingFragment on Filing {\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n':
		types.StandaloneRuleDraftEditor_FilingFragmentFragmentDoc,
	"\n\tmutation updateRuleDraft(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$manualHistoryUpdate: String\n\t\t$useManualHistoryUpdate: Boolean\n\t\t$penalty: String\n\t\t$pageBreakBefore: Boolean\n\t\t$filingID: ID!\n\t) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\tmanualHistoryUpdate: $manualHistoryUpdate\n\t\t\tuseManualHistoryUpdate: $useManualHistoryUpdate\n\t\t\tpenalty: $penalty\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateRuleDraftDocument,
	"\n\tmutation resetRuleDraft($id: ID!) {\n\t\tresetRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.ResetRuleDraftDocument,
	"\n\tfragment strikethroughPreview_FilingFragment on Filing {\n\t\tid\n\t\tagencyID\n\n\t\tcurrentSubmission {\n\t\t\tstrikethroughStyle\n\t\t\tstrikethroughUrl\n\t\t}\n\t}\n":
		types.StrikethroughPreview_FilingFragmentFragmentDoc,
	"\n\tmutation setStrikethroughStyle($id: ID!, $style: StrikethroughStyle!) {\n\t\tsetStrikethroughStyle(id: $id, style: $style) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.SetStrikethroughStyleDocument,
	"\n\tquery eSigner($filingID: ID!) {\n\t\tfiling(id: $filingID) {\n\t\t\t...processForms_FilingFragment\n\n\t\t\tagency {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tname\n\t\t\tscope\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\t\t\t\t\tname\n\t\t\t\t\tsignatureRequired\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ESignerDocument,
	"\n\tmutation updateAgency(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$shown: Boolean\n\t\t$chapters: [ID!]\n\t\t$sortTitle: String\n\t) {\n\t\tupdateAgency(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tshown: $shown\n\t\t\tchapters: $chapters\n\t\t\tsortTitle: $sortTitle\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateAgencyDocument,
	"\n\tfragment agencyEditor_AgencyFragment on Agency {\n\t\tid\n\n\t\tcontrolNumber\n\t\ttitle\n\t\tsortTitle\n\n\t\tshown\n\t\tshowToPublic\n\n\t\tchapters {\n\t\t\t...viewChapterButton_ChapterFragment\n\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tfileUrl\n\n\t\t\tshown\n\t\t}\n\t}\n":
		types.AgencyEditor_AgencyFragmentFragmentDoc,
	"\n\tfragment agencyEditor_QueryFragment on Query {\n\t\t# ... on Query @defer {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t\t# }\n\t}\n":
		types.AgencyEditor_QueryFragmentFragmentDoc,
	"\n\tmutation deleteChapter($id: ID!) {\n\t\tpermanentlyDeleteChapter(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.DeleteChapterDocument,
	"\n\tfragment chapterEditor_ChapterFragment on Chapter {\n\t\t...viewChapterButton_ChapterFragment\n\n\t\tid\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\tagencyTitle\n\t\teditorsNote\n\t\teditorsEndnote\n\n\t\tidText\n\n\t\theader\n\t\tfooter\n\t\tshown\n\n\t\trules {\n\t\t\t...viewRuleButton_RuleFragment\n\t\t\t...ruleEditor_RuleFragment # @defer\n\t\t\tid\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n":
		types.ChapterEditor_ChapterFragmentFragmentDoc,
	"\n\tmutation updateChapter(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$editorsNote: String\n\t\t$editorsEndnote: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$header: String\n\t\t$footer: String\n\t\t$shown: Boolean\n\t\t$rules: [ChapterEditorRule!]\n\t) {\n\t\tupdateChapter(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\teditorsNote: $editorsNote\n\t\t\teditorsEndnote: $editorsEndnote\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\theader: $header\n\t\t\tfooter: $footer\n\t\t\tshown: $shown\n\t\t\trules: $rules\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateChapterDocument,
	"\n\tmutation deleteRule($id: ID!) {\n\t\tpermanentlyDeleteRule(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n":
		types.DeleteRuleDocument,
	"\n\tquery editorSelector_Query($controlNumberOrIdText: String!, $skipDocument: Boolean!) {\n\t\t...agencyEditor_QueryFragment\n\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText) @skip(if: $skipDocument) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyEditor_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterEditor_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleEditor_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.EditorSelector_QueryDocument,
	"\n\tfragment ruleEditor_RuleFragment on Rule {\n\t\t...viewRuleButton_RuleFragment\n\n\t\tid\n\t\tidText\n\t\tdescription\n\t\ttitle\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tshown\n\t\tpageBreakBefore\n\t}\n":
		types.RuleEditor_RuleFragmentFragmentDoc,
	"\n\tmutation updateRule(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$history: String\n\t\t$penalty: String\n\t\t$editorsNote: String\n\t\t$shown: Boolean\n\t\t$pageBreakBefore: Boolean\n\t) {\n\t\tupdateRule(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\thistory: $history\n\t\t\tpenalty: $penalty\n\t\t\teditorsNote: $editorsNote\n\t\t\tshown: $shown\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpdateRuleDocument,
	"\n\tquery activityLog($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tactivityLog {\n\t\t\t\tid\n\n\t\t\t\t__typename\n\n\t\t\t\tdate\n\t\t\t\tuser {\n\t\t\t\t\tname\n\t\t\t\t\temail\n\t\t\t\t}\n\n\t\t\t\t... on WithDiffLogEntry {\n\t\t\t\t\twasReset\n\t\t\t\t}\n\n\t\t\t\t... on ChapterDraftUpdatedLogEntry {\n\t\t\t\t\tchapterUpdated\n\t\t\t\t\tupdatedRules\n\t\t\t\t\treorderedRules\n\t\t\t\t\taddedRule\n\t\t\t\t\tdeletedRule\n\t\t\t\t}\n\n\t\t\t\t... on WithFileLogEntry {\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\n\t\t\t\t... on FormChangedLogEntry {\n\t\t\t\t\tform {\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t... on FormUpdatedLogEntry {\n\t\t\t\t\twasNew\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ActivityLogDocument,
	"\n\tquery FilingActivityLogDetailsModal($id: ID!) {\n\t\tfilingActivityLogEntry(id: $id) {\n\t\t\t__typename\n\n\t\t\t... on WithDiffLogEntry {\n\t\t\t\tdiff\n\t\t\t}\n\n\t\t\t... on WithFileLogEntry {\n\t\t\t\tfileUrl\n\t\t\t}\n\n\t\t\t... on FormChangedLogEntry {\n\t\t\t\tform {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on FilingRejectedLogEntry {\n\t\t\t\tcomments\n\t\t\t}\n\t\t}\n\t}\n":
		types.FilingActivityLogDetailsModalDocument,
	'\n\tfragment draftRow_Filing on Filing {\n\t\tid\n\n\t\tscope\n\t\tintendedAction\n\n\t\tstatus\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n':
		types.DraftRow_FilingFragmentDoc,
	"\n\tquery filingChecklist($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...formRow_Filing\n\t\t\t...draftRow_Filing\n\t\t\t...processForms_FilingFragment\n\n\t\t\tstatus\n\t\t\tagencyID\n\t\t\tname\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tstatus\n\n\t\t\tfilingType {\n\t\t\t\thasProposal\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tisProposal\n\t\t\t\tstrikethroughUrl\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\n\t\t\t\t\tcondition\n\t\t\t\t\tsignatureRequired\n\n\t\t\t\t\t...formRow_Form\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\n\t\t\t\t\tvalues {\n\t\t\t\t\t\tkey\n\t\t\t\t\t\ttext\n\t\t\t\t\t}\n\n\t\t\t\t\t...formRow_FormEntry\n\t\t\t\t}\n\n\t\t\t\trejectionComments {\n\t\t\t\t\tcomment\n\t\t\t\t\tcreatedAt\n\t\t\t\t}\n\n\t\t\t\tfileUrl\n\t\t\t}\n\t\t}\n\t}\n":
		types.FilingChecklistDocument,
	"\n\tfragment formRow_Filing on Filing {\n\t\t...processForms_FilingFragment\n\n\t\tcurrentSubmission {\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n":
		types.FormRow_FilingFragmentDoc,
	"\n\tfragment formRow_Form on Form {\n\t\tkey\n\t\tname\n\t\tdescription\n\n\t\tfields {\n\t\t\tkey\n\t\t\ttext\n\t\t\thelperText\n\t\t\ttype\n\t\t\tcondition\n\n\t\t\toptions {\n\t\t\t\ttext\n\t\t\t\tvalue\n\t\t\t}\n\n\t\t\tdateSpecs {\n\t\t\t\tdefault\n\t\t\t\tfrom\n\t\t\t}\n\n\t\t\ttextSpecs {\n\t\t\t\tdefault\n\t\t\t}\n\t\t}\n\t}\n":
		types.FormRow_FormFragmentDoc,
	"\n\tfragment formRow_FormEntry on FormEntry {\n\t\tvalues {\n\t\t\tkey\n\t\t\tnum\n\t\t\ttext\n\t\t\tdate\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n":
		types.FormRow_FormEntryFragmentDoc,
	"\n\tmutation upsertFormEntry(\n\t\t$formKey: String!\n\t\t$filingID: ID!\n\t\t$values: [FormEntryValueInput!]!\n\t) {\n\t\tupsertFormEntry(formKey: $formKey, filingID: $filingID, values: $values) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.UpsertFormEntryDocument,
	"\n\tquery filingsViewer(\n\t\t$type: PublicationType!\n\t\t$filingTypeKey: String\n\t\t$fromPublication: Boolean!\n\t) {\n\t\tpublication(currentPublication: true) @include(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\tfilings(type: $type) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\t\t}\n\n\t\tfilings(\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tstatus: [certificationEffectiveNotPublished, certificationPublishedAndEffective]\n\t\t) @skip(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_FilingFragment\n\t\t}\n\n\t\t...publicationArchives_QueryFragment\n\t}\n":
		types.FilingsViewerDocument,
	"\n\tmutation sendPasswordReset($email: String!) {\n\t\tsendPasswordResetLink(email: $email)\n\t}\n":
		types.SendPasswordResetDocument,
	"\n\tmutation login($email: String!, $password: String!) {\n\t\tlogin(email: $email, password: $password) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n":
		types.LoginDocument,
	"\n\tquery pipelineLanding(\n\t\t$status: [FilingStatus!]\n\t\t$agencyID: ID\n\t\t$showEffectiveDate: Boolean!\n\t) {\n\t\t...newDraftButton_QueryFragment\n\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tpublicationDate\n\t\t}\n\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t}\n\n\t\tfilings(agencyID: $agencyID, status: $status) {\n\t\t\t...filingRow_Filing\n\n\t\t\tid\n\t\t\tfilingTypeKey\n\t\t\tintendedAction\n\n\t\t\tagency {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t\tsortTitle\n\t\t\t}\n\n\t\t\tcurrentCodeNumber\n\n\t\t\tcurrentSubmission {\n\t\t\t\tnextPublication {\n\t\t\t\t\tid\n\t\t\t\t\tpublicationDate\n\t\t\t\t}\n\n\t\t\t\tdraft {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.PipelineLandingDocument,
	"\n\tfragment agencyHome_AgencyFragment on Agency {\n\t\tcontrolNumber\n\t\ttitle\n\n\t\tchapters(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...agencyHome_ChapterFragment # @defer\n\t\t}\n\t}\n":
		types.AgencyHome_AgencyFragmentFragmentDoc,
	"\n\tfragment agencyHome_ChapterFragment on Chapter {\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n":
		types.AgencyHome_ChapterFragmentFragmentDoc,
	"\n\tfragment chapterHome_ChapterFragment on Chapter {\n\t\tid\n\n\t\tidText\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...chapterHome_RuleFragment # @defer\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n":
		types.ChapterHome_ChapterFragmentFragmentDoc,
	"\n\tfragment chapterHome_RuleFragment on Rule {\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n":
		types.ChapterHome_RuleFragmentFragmentDoc,
	"\n\tquery publicCode($controlNumberOrIdText: String!) {\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText, onlyShown: true) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyHome_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterHome_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleHome_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n":
		types.PublicCodeDocument,
	"\n\tfragment ruleHome_RuleFragment on Rule {\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tfileUrl\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n":
		types.RuleHome_RuleFragmentFragmentDoc,
	"\n\tquery requestAccountQuery {\n\t\tagencies {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n":
		types.RequestAccountQueryDocument,
	"\n\tmutation requestAccount(\n\t\t$name: String!\n\t\t$email: String!\n\t\t$password: String!\n\t\t$controlNumbers: [String!]!\n\t) {\n\t\trequestAccount(\n\t\t\tname: $name\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t\tagencyControlNumbers: $controlNumbers\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.RequestAccountDocument,
	"\n\tquery userEmailForToken($token: String!) {\n\t\tuserEmailForToken(token: $token)\n\t}\n":
		types.UserEmailForTokenDocument,
	"\n\tmutation resetPassword($password: String!, $token: String!) {\n\t\tresetPassword(password: $password, token: $token)\n\t}\n":
		types.ResetPasswordDocument,
	"\n\tquery resourcesAndAnnouncementsQuery {\n\t\t...resourceList_QueryFragment\n\t\t...announcementList_QueryFragment\n\t}\n":
		types.ResourcesAndAnnouncementsQueryDocument,
	"\n\tquery allAgenciesQuery {\n\t\t...adminCodeSearch_QueryFragment\n\t\t...publicationArchiveSearch_QueryFragment\n\t}\n":
		types.AllAgenciesQueryDocument,
	"\n\tquery strikethrough($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tstrikethroughUrl\n\t\t\t\tstrikethroughViewed\n\n\t\t\t\t# Disabled per INS-512\n\t\t\t\t# overrideStrikethrough {\n\t\t\t\t# \tfilename\n\t\t\t\t# }\n\t\t\t}\n\t\t}\n\t}\n":
		types.StrikethroughDocument,
	"\n\t# Disabled per INS-512\n\t# mutation updateStrikethrough($filingID: ID! , $override: File) {\n\tmutation updateStrikethrough($filingID: ID!) {\n\t\tapproveStrikethrough(id: $filingID, viewed: true) {\n\t\t\t__typename\n\t\t}\n\n\t\t# Disabled per INS-512\n\t\t# overrideStrikethroughForFiling(id: $filingID, override: $override) {\n\t\t# \t__typename\n\t\t# }\n\t}\n":
		types.UpdateStrikethroughDocument,
	"\n\tquery upcomingPublication {\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tid\n\t\t\tpublicationDate\n\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\taamFilings: filings(type: aam) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tproposals: filings(type: proposal) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tapproved\n\n\t\t\teditorsNote\n\t\t}\n\t}\n":
		types.UpcomingPublicationDocument,
	"\n\tmutation setPublicationApproved($id: String!, $approved: Boolean!) {\n\t\tsetPublicationApproved(id: $id, approved: $approved) {\n\t\t\tapproved\n\t\t}\n\t}\n":
		types.SetPublicationApprovedDocument,
	"\n\tmutation updatePublicationEditorsNote($id: String!, $editorsNote: String!) {\n\t\tupdatePublicationEditorsNote(id: $id, editorsNote: $editorsNote) {\n\t\t\teditorsNote\n\t\t}\n\t}\n":
		types.UpdatePublicationEditorsNoteDocument,
	"\n\tquery userProfile($id: ID!) {\n\t\t...userProfile_QueryFragment # @defer\n\t\tuser(id: $id) {\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tsubscribedToEmails\n\t\t}\n\t}\n":
		types.UserProfileDocument,
	"\n\tfragment userProfile_QueryFragment on Query {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n":
		types.UserProfile_QueryFragmentFragmentDoc,
	"\n\tmutation updateUser(\n\t\t$id: ID!\n\t\t$email: String\n\t\t$isAdmin: Boolean\n\t\t$isActive: Boolean\n\t\t$name: String\n\t\t$pendingAgencyIDs: [String!]\n\t\t$subscribedToEmails: Boolean\n\t) {\n\t\tupdateUser(\n\t\t\tid: $id\n\t\t\temail: $email\n\t\t\tisAdmin: $isAdmin\n\t\t\tisActive: $isActive\n\t\t\tname: $name\n\t\t\tpendingAgencyIDs: $pendingAgencyIDs\n\t\t\tsubscribedToEmails: $subscribedToEmails\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n":
		types.UpdateUserDocument,
	"\n\tmutation approveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.ApproveUserDocument,
	"\n\tmutation disapproveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tdisapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DisapproveUserDocument,
	"\n\tmutation requestAgency($agency: ID!) {\n\t\trequestAgency(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.RequestAgencyDocument,
	"\n\tmutation cancelAgencyRequest($agency: ID!) {\n\t\tcancelAgencyRequest(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.CancelAgencyRequestDocument,
	"\n\tmutation deleteUser($id: ID!) {\n\t\tpermanentlyDeleteUser(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DeleteUserDocument,
	"\n\tquery viewPublication($id: ID!, $type: PublicationType!) {\n\t\tpublicationOrHistoricalPublication(id: $id, type: $type) {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\t... on Publication {\n\t\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\t\tfilings(type: $type) {\n\t\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on HistoricalPublication {\n\t\t\t\tcontents(type: $type) {\n\t\t\t\t\tcontent\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n":
		types.ViewPublicationDocument,
	"\n\tquery announcementsMetaAndResources {\n\t\tannouncements {\n\t\t\t__typename\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\n\t\tresources {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\ttitle\n\n\t\t\tfileUrl\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n":
		types.AnnouncementsMetaAndResourcesDocument,
	"\n\tquery announcementsContents {\n\t\tannouncements {\n\t\t\tid\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n":
		types.AnnouncementsContentsDocument,
	"\n\tmutation sortAnnouncements($announcements: [ID!]!) {\n\t\tsortAnnouncements(announcements: $announcements)\n\t}\n":
		types.SortAnnouncementsDocument,
	"\n\tmutation sortResources($resources: [ID!]!) {\n\t\tsortResources(resources: $resources)\n\t}\n":
		types.SortResourcesDocument,
	"\n\tmutation deleteAnnouncement($id: ID!) {\n\t\tpermanentlyDeleteAnnouncement(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DeleteAnnouncementDocument,
	"\n\tmutation deleteResource($id: ID!) {\n\t\tpermanentlyDeleteResource(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n":
		types.DeleteResourceDocument,
	"\n\tfragment processForms_FilingFragment on Filing {\n\t\tstatus\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tforms {\n\t\t\t\tkey\n\n\t\t\t\tdependsOnStrikethrough\n\t\t\t\tdependsOnStrikethroughCondition\n\t\t\t\tsignatureRequired\n\n\t\t\t\tfields {\n\t\t\t\t\tkey\n\t\t\t\t\tcondition\n\t\t\t\t\ttype\n\t\t\t\t\toptional\n\n\t\t\t\t\tdateSpecs {\n\t\t\t\t\t\tmax\n\t\t\t\t\t\tmin\n\t\t\t\t\t\tfrom\n\t\t\t\t\t}\n\n\t\t\t\t\tfileSpecs {\n\t\t\t\t\t\ttype\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tformKey\n\n\t\t\t\tvalues {\n\t\t\t\t\tkey\n\t\t\t\t\tnum\n\t\t\t\t\ttext\n\t\t\t\t\tdate\n\t\t\t\t\tfile {\n\t\t\t\t\t\t__typename\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\tsignatureID\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n":
		types.ProcessForms_FilingFragmentFragmentDoc
}

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery search(\n\t\t$query: String!\n\t\t$agencyControlNumbers: [String!]\n\t\t$chapterIdTexts: [String!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearch(\n\t\t\tquery: $query\n\t\t\tagencyControlNumbers: $agencyControlNumbers\n\t\t\tchapterIdTexts: $chapterIdTexts\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\n\t\t\t\t... on ChapterResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t}\n\n\t\t\t\t... on RuleResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t\tdescription\n\t\t\t\t\tauthor\n\t\t\t\t\teditorsNote\n\t\t\t\t}\n\n\t\t\t\t... on AgencyResultItem {\n\t\t\t\t\tcontrolNumber\n\t\t\t\t}\n\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery search(\n\t\t$query: String!\n\t\t$agencyControlNumbers: [String!]\n\t\t$chapterIdTexts: [String!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearch(\n\t\t\tquery: $query\n\t\t\tagencyControlNumbers: $agencyControlNumbers\n\t\t\tchapterIdTexts: $chapterIdTexts\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\n\t\t\t\t... on ChapterResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t}\n\n\t\t\t\t... on RuleResultItem {\n\t\t\t\t\tidText\n\t\t\t\t\tparentID\n\t\t\t\t\tdescription\n\t\t\t\t\tauthor\n\t\t\t\t\teditorsNote\n\t\t\t\t}\n\n\t\t\t\t... on AgencyResultItem {\n\t\t\t\t\tcontrolNumber\n\t\t\t\t}\n\n\t\t\t\t__typename\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment adminCodeSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t\t__typename\n\t\t\t}\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment adminCodeSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t\t__typename\n\t\t\t}\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment announcementList_QueryFragment on Query {\n\t\tannouncements {\n\t\t\ttitle\n\t\t\tupdatedAt\n\t\t\tdescription\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment announcementList_QueryFragment on Query {\n\t\tannouncements {\n\t\t\ttitle\n\t\t\tupdatedAt\n\t\t\tdescription\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment filingHistory_FilingsFragment on Filing {\n\t\tname\n\t\tscope\n\t\tfilingTypeKey\n\t\tintendedAction\n\t\tcurrentCodeNumber\n\t\teffectiveDate\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tid\n\t\t\tfileUrl\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment filingHistory_FilingsFragment on Filing {\n\t\tname\n\t\tscope\n\t\tfilingTypeKey\n\t\tintendedAction\n\t\tcurrentCodeNumber\n\t\teffectiveDate\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tid\n\t\t\tfileUrl\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment filingRow_Filing on Filing {\n\t\tid\n\t\tname\n\n\t\tintendedAction\n\t\tscope\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\n\t\tagency {\n\t\t\ttitle\n\t\t}\n\n\t\tfilingType {\n\t\t\tname\n\t\t}\n\n\t\tfilingTypeKey\n\n\t\t# ... on Filing @defer {\n\t\t...processForms_FilingFragment\n\n\t\tstatus\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tfileUrl\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tfileUrl\n\n\t\t\tsubmissionDate\n\t\t\tresubmissionDate\n\t\t\tisProposal\n\t\t\tstrikethroughUrl\n\n\t\t\trejectionComments {\n\t\t\t\t__typename\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tsubmissionDeadline\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tvalues {\n\t\t\t\t\tdate\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tfilingType {\n\t\t\thasProposal\n\t\t\tcanBeWithdrawnOrDisapproved\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t\t# }\n\n\t\tactivityLog {\n\t\t\t__typename\n\t\t}\n\n\t\teffectiveDate @include(if: $showEffectiveDate)\n\t}\n"
): (typeof documents)["\n\tfragment filingRow_Filing on Filing {\n\t\tid\n\t\tname\n\n\t\tintendedAction\n\t\tscope\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\tidText\n\t\t\t}\n\t\t}\n\n\t\tagency {\n\t\t\ttitle\n\t\t}\n\n\t\tfilingType {\n\t\t\tname\n\t\t}\n\n\t\tfilingTypeKey\n\n\t\t# ... on Filing @defer {\n\t\t...processForms_FilingFragment\n\n\t\tstatus\n\n\t\tproposal {\n\t\t\tfileUrl\n\t\t}\n\n\t\tlatestCertification {\n\t\t\tfileUrl\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tfileUrl\n\n\t\t\tsubmissionDate\n\t\t\tresubmissionDate\n\t\t\tisProposal\n\t\t\tstrikethroughUrl\n\n\t\t\trejectionComments {\n\t\t\t\t__typename\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tsubmissionDeadline\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tvalues {\n\t\t\t\t\tdate\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\tuseManualHistoryUpdate\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\tfilingType {\n\t\t\thasProposal\n\t\t\tcanBeWithdrawnOrDisapproved\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t\t# }\n\n\t\tactivityLog {\n\t\t\t__typename\n\t\t}\n\n\t\teffectiveDate @include(if: $showEffectiveDate)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteFiling($id: ID!) {\n\t\tpermanentlyDeleteFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteFiling($id: ID!) {\n\t\tpermanentlyDeleteFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation submitFiling($id: ID!) {\n\t\tsubmitFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation submitFiling($id: ID!) {\n\t\tsubmitFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation recallFiling($id: ID!) {\n\t\trecallFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation recallFiling($id: ID!) {\n\t\trecallFiling(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation approveFiling($id: ID!) {\n\t\tapproveFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation approveFiling($id: ID!) {\n\t\tapproveFiling(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation withdrawOrDisapproveFiling($id: ID!, $action: FilingUserAction!) {\n\t\twithdrawOrDisapproveFiling(id: $id, action: $action) {\n\t\t\tstatus\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation withdrawOrDisapproveFiling($id: ID!, $action: FilingUserAction!) {\n\t\twithdrawOrDisapproveFiling(id: $id, action: $action) {\n\t\t\tstatus\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation rejectFiling($id: ID!, $comment: String!) {\n\t\trejectFiling(id: $id, comment: $comment) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation rejectFiling($id: ID!, $comment: String!) {\n\t\trejectFiling(id: $id, comment: $comment) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation logout {\n\t\tlogout\n\t}\n"
): (typeof documents)["\n\tmutation logout {\n\t\tlogout\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addAgency($controlNumber: String!, $title: String!) {\n\t\taddAgency(controlNumber: $controlNumber, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addAgency($controlNumber: String!, $title: String!) {\n\t\taddAgency(controlNumber: $controlNumber, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addAnnouncement($title: String!, $description: String!, $content: String!) {\n\t\taddAnnouncement(title: $title, description: $description, content: $content) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addAnnouncement($title: String!, $description: String!, $content: String!) {\n\t\taddAnnouncement(title: $title, description: $description, content: $content) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addChapter($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddChapter(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addChapter($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddChapter(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addDraftFiling(\n\t\t$agencyID: ID!\n\t\t$filingTypeKey: String!\n\t\t$name: String\n\t\t$scope: FilingScope\n\t\t$intendedAction: IntendedAction\n\t\t$currentCodeNumber: String\n\t) {\n\t\taddFiling(\n\t\t\tagencyID: $agencyID\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tintendedAction: $intendedAction\n\t\t\tname: $name\n\t\t\tscope: $scope\n\t\t\tcurrentCodeNumber: $currentCodeNumber\n\t\t) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addDraftFiling(\n\t\t$agencyID: ID!\n\t\t$filingTypeKey: String!\n\t\t$name: String\n\t\t$scope: FilingScope\n\t\t$intendedAction: IntendedAction\n\t\t$currentCodeNumber: String\n\t) {\n\t\taddFiling(\n\t\t\tagencyID: $agencyID\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tintendedAction: $intendedAction\n\t\t\tname: $name\n\t\t\tscope: $scope\n\t\t\tcurrentCodeNumber: $currentCodeNumber\n\t\t) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment newDraftButton_QueryFragment on Query {\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment newDraftButton_QueryFragment on Query {\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t\trequiresScopeAndIntendedAction\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery newDraftButton($agencyID: ID!, $needsChapter: Boolean!, $needsRules: Boolean!) {\n\t\tagency(id: $agencyID) {\n\t\t\tchapters {\n\t\t\t\t... on Chapter @include(if: $needsChapter) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\n\t\t\t\trules @include(if: $needsRules) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery newDraftButton($agencyID: ID!, $needsChapter: Boolean!, $needsRules: Boolean!) {\n\t\tagency(id: $agencyID) {\n\t\t\tchapters {\n\t\t\t\t... on Chapter @include(if: $needsChapter) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\n\t\t\t\trules @include(if: $needsRules) {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addResource($title: String!, $file: File!) {\n\t\taddResource(title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addResource($title: String!, $file: File!) {\n\t\taddResource(title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addRule($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddRule(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addRule($parentID: ID!, $idText: String!, $title: String!) {\n\t\taddRule(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation addRuleDraft($parentID: ID!, $idText: String, $title: String) {\n\t\taddRuleDraft(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation addRuleDraft($parentID: ID!, $idText: String, $title: String) {\n\t\taddRuleDraft(parentID: $parentID, idText: $idText, title: $title) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment publicationArchives_QueryFragment on Query {\n\t\tpublicationArchives(type: $type) {\n\t\t\tid\n\t\t\tpublicationDate\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment publicationArchives_QueryFragment on Query {\n\t\tpublicationArchives(type: $type) {\n\t\t\tid\n\t\t\tpublicationDate\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery searchFilings(\n\t\t$query: String!\n\t\t$controlNumbers: [String!]\n\t\t$years: [Int!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearchFilings(\n\t\t\tquery: $query\n\t\t\tcontrolNumbers: $controlNumbers\n\t\t\tyears: $years\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontent\n\t\t\t\tpublicationDate\n\t\t\t\tfileUrl\n\t\t\t\t__typename\n\n\t\t\t\t... on HistoricalFilingResultItem {\n\t\t\t\t\tpublicationType\n\t\t\t\t}\n\n\t\t\t\t... on FilingResultItem {\n\t\t\t\t\tisProposal\n\t\t\t\t\tfilingType\n\t\t\t\t\twithdrawDate\n\t\t\t\t\tdisapproveDate\n\t\t\t\t\tdisapproveWithSuggestionsDate\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery searchFilings(\n\t\t$query: String!\n\t\t$controlNumbers: [String!]\n\t\t$years: [Int!]\n\t\t$skip: Int\n\t\t$limit: Int\n\t) {\n\t\tsearchFilings(\n\t\t\tquery: $query\n\t\t\tcontrolNumbers: $controlNumbers\n\t\t\tyears: $years\n\t\t\tskip: $skip\n\t\t\tlimit: $limit\n\t\t) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontent\n\t\t\t\tpublicationDate\n\t\t\t\tfileUrl\n\t\t\t\t__typename\n\n\t\t\t\t... on HistoricalFilingResultItem {\n\t\t\t\t\tpublicationType\n\t\t\t\t}\n\n\t\t\t\t... on FilingResultItem {\n\t\t\t\t\tisProposal\n\t\t\t\t\tfilingType\n\t\t\t\t\twithdrawDate\n\t\t\t\t\tdisapproveDate\n\t\t\t\t\tdisapproveWithSuggestionsDate\n\t\t\t\t}\n\t\t\t}\n\t\t\ttotalCount\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment publicationArchiveSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment publicationArchiveSearch_QueryFragment on Query {\n\t\tagencies(onlyShown: true) {\n\t\t\tid\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\tcontrolNumber\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation repealRuleDraft($id: ID!, $title: String!, $description: String!) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tuseManualHistoryUpdate: false\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation repealRuleDraft($id: ID!, $title: String!, $description: String!) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tuseManualHistoryUpdate: false\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment repealRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t}\n"
): (typeof documents)["\n\tfragment repealRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment resourceList_QueryFragment on Query {\n\t\tresources {\n\t\t\ttitle\n\t\t\tfileUrl\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment resourceList_QueryFragment on Query {\n\t\tresources {\n\t\t\ttitle\n\t\t\tfileUrl\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation signFormEntry(\n\t\t$filingID: ID!\n\t\t$formKey: String!\n\t\t$value: String!\n\t\t$screenshot: File!\n\t) {\n\t\tsignFormEntry(\n\t\t\tfilingID: $filingID\n\t\t\tformKey: $formKey\n\n\t\t\tvalue: $value\n\t\t\tscreenshot: $screenshot\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation signFormEntry(\n\t\t$filingID: ID!\n\t\t$formKey: String!\n\t\t$value: String!\n\t\t$screenshot: File!\n\t) {\n\t\tsignFormEntry(\n\t\t\tfilingID: $filingID\n\t\t\tformKey: $formKey\n\n\t\t\tvalue: $value\n\t\t\tscreenshot: $screenshot\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateAnnouncement(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$description: String\n\t\t$content: String\n\t) {\n\t\tupdateAnnouncement(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tcontent: $content\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateAnnouncement(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$description: String\n\t\t$content: String\n\t) {\n\t\tupdateAnnouncement(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tcontent: $content\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateResource($id: ID!, $title: String!, $file: File) {\n\t\tupdateResource(id: $id, title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateResource($id: ID!, $title: String!, $file: File) {\n\t\tupdateResource(id: $id, title: $title, file: $file) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery users {\n\t\tusers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery users {\n\t\tusers {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment viewChapterButton_ChapterFragment on Chapter {\n\t\tidText\n\t\tfileUrl\n\t\tshowToPublic\n\t}\n"
): (typeof documents)["\n\tfragment viewChapterButton_ChapterFragment on Chapter {\n\t\tidText\n\t\tfileUrl\n\t\tshowToPublic\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment viewCurrentFilings_PublicationFragment on Publication {\n\t\tid\n\t\ttitle\n\t\tpublicationDate\n\t\teditorsNote\n\t}\n"
): (typeof documents)["\n\tfragment viewCurrentFilings_PublicationFragment on Publication {\n\t\tid\n\t\ttitle\n\t\tpublicationDate\n\t\teditorsNote\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment viewCurrentFilings_FilingFragment on Filing {\n\t\tfilingType {\n\t\t\tkey\n\t\t\tname\n\n\t\t\thasProposal\n\t\t\tshouldMergeIntoCurrentCode\n\t\t}\n\n\t\tscope\n\t\tintendedAction\n\n\t\tagency {\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\theadingForNonstandardSectionInAam\n\t\t}\n\n\t\tproposal {\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tfileUrl\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\tcertification {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\ttitleFromFormEntry: formAnswer(formKey: "misc-notice", question: "title") {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\trecertifications {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\teffectiveDate\n\t\tisEffective\n\t}\n'
): (typeof documents)['\n\tfragment viewCurrentFilings_FilingFragment on Filing {\n\t\tfilingType {\n\t\t\tkey\n\t\t\tname\n\n\t\t\thasProposal\n\t\t\tshouldMergeIntoCurrentCode\n\t\t}\n\n\t\tscope\n\t\tintendedAction\n\n\t\tagency {\n\t\t\ttitle\n\t\t\tsortTitle\n\t\t\theadingForNonstandardSectionInAam\n\t\t}\n\n\t\tproposal {\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tfileUrl\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\tcertification {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\ttitleFromFormEntry: formAnswer(formKey: "misc-notice", question: "title") {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\trecertifications {\n\t\t\tdraft {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpublication {\n\t\t\t\tid\n\n\t\t\t\ttitle\n\t\t\t\tpublicationDate\n\t\t\t}\n\n\t\t\tsubmissionDate\n\n\t\t\twithdrawOrDisapprovePublicationID\n\t\t\twithdrawDate\n\t\t\tdisapproveDate\n\t\t\tdisapproveWithSuggestionsDate\n\n\t\t\tfileUrl\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\n\t\t\tisProposal\n\t\t}\n\n\t\teffectiveDate\n\t\tisEffective\n\t}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment viewRuleButton_RuleFragment on Rule {\n\t\tidText\n\t\tfileUrl\n\n\t\t...viewRuleButton_DeferredRuleFragment # @defer\n\t}\n"
): (typeof documents)["\n\tfragment viewRuleButton_RuleFragment on Rule {\n\t\tidText\n\t\tfileUrl\n\n\t\t...viewRuleButton_DeferredRuleFragment # @defer\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment viewRuleButton_DeferredRuleFragment on Rule {\n\t\ttitle\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n"
): (typeof documents)["\n\tfragment viewRuleButton_DeferredRuleFragment on Rule {\n\t\ttitle\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment viewRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t\tidText\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t}\n"
): (typeof documents)["\n\tfragment viewRuleDraftButton_RuleDraftFragment on RuleDraft {\n\t\tid\n\t\ttitle\n\t\tidText\n\t\tdescription\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery user {\n\t\tuser {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery user {\n\t\tuser {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery agencySortTitles {\n\t\tagencies(onlyShown: true) {\n\t\t\tsortTitle\n\t\t\ttitle\n\t\t\tcontrolNumber\n\t\t\tshown\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery agencySortTitles {\n\t\tagencies(onlyShown: true) {\n\t\t\tsortTitle\n\t\t\ttitle\n\t\t\tcontrolNumber\n\t\t\tshown\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery announcementByTitle($title: String!) {\n\t\tannouncement(title: $title) {\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery announcementByTitle($title: String!) {\n\t\tannouncement(title: $title) {\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment chapterDraftEditor_FilingFragment on Filing {\n\t\t...ruleDraftEditor_FilingFragment\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\t\tintendedAction\n\n\t\tagency {\n\t\t\tcontrolNumber\n\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\tid\n\n\t\t\t\t\tagencyTitle\n\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\t...viewRuleDraftButton_RuleDraftFragment\n\t\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment # @defer\n\t\t\t\t\t\t...repealRuleDraftButton_RuleDraftFragment # @defer\n\t\t\t\t\t\tid\n\n\t\t\t\t\t\tidText\n\t\t\t\t\t\ttitle\n\n\t\t\t\t\t\tisValid\n\n\t\t\t\t\t\tproposalCertification\n\t\t\t\t\t}\n\n\t\t\t\t\toverridePlacement\n\t\t\t\t\tplaceAt\n\t\t\t\t\tplaceAfterID\n\n\t\t\t\t\tproposalCertification\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on Chapter {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tfragment chapterDraftEditor_FilingFragment on Filing {\n\t\t...ruleDraftEditor_FilingFragment\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\t\tintendedAction\n\n\t\tagency {\n\t\t\tcontrolNumber\n\n\t\t\tchapters {\n\t\t\t\tid\n\t\t\t\tidText\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterDraft {\n\t\t\t\t\tid\n\n\t\t\t\t\tagencyTitle\n\n\t\t\t\t\tidText\n\t\t\t\t\ttitle\n\n\t\t\t\t\truleDrafts {\n\t\t\t\t\t\t...viewRuleDraftButton_RuleDraftFragment\n\t\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment # @defer\n\t\t\t\t\t\t...repealRuleDraftButton_RuleDraftFragment # @defer\n\t\t\t\t\t\tid\n\n\t\t\t\t\t\tidText\n\t\t\t\t\t\ttitle\n\n\t\t\t\t\t\tisValid\n\n\t\t\t\t\t\tproposalCertification\n\t\t\t\t\t}\n\n\t\t\t\t\toverridePlacement\n\t\t\t\t\tplaceAt\n\t\t\t\t\tplaceAfterID\n\n\t\t\t\t\tproposalCertification\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on Chapter {\n\t\t\t\t\tid\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateChapterDraft(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$ruleDrafts: [ID!]\n\t\t$ruleDraftsToSave: [RuleDraftToSave!]\n\t\t$overridePlacement: Boolean\n\t\t$placeAt: Placement\n\t\t$placeAfterID: ID\n\t\t$filingID: ID!\n\t) {\n\t\tupdateChapterDraft(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\truleDrafts: $ruleDrafts\n\t\t\truleDraftsToSave: $ruleDraftsToSave\n\t\t\toverridePlacement: $overridePlacement\n\t\t\tplaceAt: $placeAt\n\t\t\tplaceAfterID: $placeAfterID\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateChapterDraft(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$ruleDrafts: [ID!]\n\t\t$ruleDraftsToSave: [RuleDraftToSave!]\n\t\t$overridePlacement: Boolean\n\t\t$placeAt: Placement\n\t\t$placeAfterID: ID\n\t\t$filingID: ID!\n\t) {\n\t\tupdateChapterDraft(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\truleDrafts: $ruleDrafts\n\t\t\truleDraftsToSave: $ruleDraftsToSave\n\t\t\toverridePlacement: $overridePlacement\n\t\t\tplaceAt: $placeAt\n\t\t\tplaceAfterID: $placeAfterID\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteRuleDraft($id: ID!) {\n\t\tpermanentlyDeleteRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteRuleDraft($id: ID!) {\n\t\tpermanentlyDeleteRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation resetChapterDraft($id: ID!) {\n\t\tresetChapterDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation resetChapterDraft($id: ID!) {\n\t\tresetChapterDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery draftForEditor($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...chapterDraftEditor_FilingFragment\n\t\t\t...standaloneRuleDraftEditor_FilingFragment\n\n\t\t\tid\n\t\t\tname\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tdraft {\n\t\t\t\t\t__typename\n\t\t\t\t}\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery draftForEditor($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...chapterDraftEditor_FilingFragment\n\t\t\t...standaloneRuleDraftEditor_FilingFragment\n\n\t\t\tid\n\t\t\tname\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tdraft {\n\t\t\t\t\t__typename\n\t\t\t\t}\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment ruleDraftEditor_FilingFragment on Filing {\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tfragment ruleDraftEditor_FilingFragment on Filing {\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ruleDraftEditor_RuleDraftFragment on RuleDraft {\n\t\t...repealRuleDraftButton_RuleDraftFragment\n\n\t\tid\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\tpenalty\n\n\t\tmanualHistoryUpdate\n\t\tuseManualHistoryUpdate\n\t\tgeneratedHistoryUpdate\n\n\t\tpageBreakBefore\n\t\tproposalCertification\n\n\t\tparent {\n\t\t\tidText\n\t\t}\n\n\t\tnewRule\n\t\texistingHistory\n\t}\n"
): (typeof documents)["\n\tfragment ruleDraftEditor_RuleDraftFragment on RuleDraft {\n\t\t...repealRuleDraftButton_RuleDraftFragment\n\n\t\tid\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\tpenalty\n\n\t\tmanualHistoryUpdate\n\t\tuseManualHistoryUpdate\n\t\tgeneratedHistoryUpdate\n\n\t\tpageBreakBefore\n\t\tproposalCertification\n\n\t\tparent {\n\t\t\tidText\n\t\t}\n\n\t\tnewRule\n\t\texistingHistory\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment standaloneRuleDraftEditor_FilingFragment on Filing {\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tfragment standaloneRuleDraftEditor_FilingFragment on Filing {\n\t\t...strikethroughPreview_FilingFragment\n\n\t\tid\n\t\tstatus\n\n\t\tscope\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t... on RuleDraft {\n\t\t\t\t\t...ruleDraftEditor_RuleDraftFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentCode {\n\t\t\t\t__typename\n\n\t\t\t\t... on ChapterOrSnapshot {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateRuleDraft(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$manualHistoryUpdate: String\n\t\t$useManualHistoryUpdate: Boolean\n\t\t$penalty: String\n\t\t$pageBreakBefore: Boolean\n\t\t$filingID: ID!\n\t) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\tmanualHistoryUpdate: $manualHistoryUpdate\n\t\t\tuseManualHistoryUpdate: $useManualHistoryUpdate\n\t\t\tpenalty: $penalty\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateRuleDraft(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$manualHistoryUpdate: String\n\t\t$useManualHistoryUpdate: Boolean\n\t\t$penalty: String\n\t\t$pageBreakBefore: Boolean\n\t\t$filingID: ID!\n\t) {\n\t\tupdateRuleDraft(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\tmanualHistoryUpdate: $manualHistoryUpdate\n\t\t\tuseManualHistoryUpdate: $useManualHistoryUpdate\n\t\t\tpenalty: $penalty\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\n\t\tapproveStrikethrough(id: $filingID, viewed: false) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation resetRuleDraft($id: ID!) {\n\t\tresetRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation resetRuleDraft($id: ID!) {\n\t\tresetRuleDraft(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment strikethroughPreview_FilingFragment on Filing {\n\t\tid\n\t\tagencyID\n\n\t\tcurrentSubmission {\n\t\t\tstrikethroughStyle\n\t\t\tstrikethroughUrl\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment strikethroughPreview_FilingFragment on Filing {\n\t\tid\n\t\tagencyID\n\n\t\tcurrentSubmission {\n\t\t\tstrikethroughStyle\n\t\t\tstrikethroughUrl\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation setStrikethroughStyle($id: ID!, $style: StrikethroughStyle!) {\n\t\tsetStrikethroughStyle(id: $id, style: $style) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation setStrikethroughStyle($id: ID!, $style: StrikethroughStyle!) {\n\t\tsetStrikethroughStyle(id: $id, style: $style) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery eSigner($filingID: ID!) {\n\t\tfiling(id: $filingID) {\n\t\t\t...processForms_FilingFragment\n\n\t\t\tagency {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tname\n\t\t\tscope\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\t\t\t\t\tname\n\t\t\t\t\tsignatureRequired\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery eSigner($filingID: ID!) {\n\t\tfiling(id: $filingID) {\n\t\t\t...processForms_FilingFragment\n\n\t\t\tagency {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tname\n\t\t\tscope\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\t\t\t\t\tname\n\t\t\t\t\tsignatureRequired\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateAgency(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$shown: Boolean\n\t\t$chapters: [ID!]\n\t\t$sortTitle: String\n\t) {\n\t\tupdateAgency(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tshown: $shown\n\t\t\tchapters: $chapters\n\t\t\tsortTitle: $sortTitle\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateAgency(\n\t\t$id: ID!\n\t\t$title: String\n\t\t$shown: Boolean\n\t\t$chapters: [ID!]\n\t\t$sortTitle: String\n\t) {\n\t\tupdateAgency(\n\t\t\tid: $id\n\t\t\ttitle: $title\n\t\t\tshown: $shown\n\t\t\tchapters: $chapters\n\t\t\tsortTitle: $sortTitle\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment agencyEditor_AgencyFragment on Agency {\n\t\tid\n\n\t\tcontrolNumber\n\t\ttitle\n\t\tsortTitle\n\n\t\tshown\n\t\tshowToPublic\n\n\t\tchapters {\n\t\t\t...viewChapterButton_ChapterFragment\n\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tfileUrl\n\n\t\t\tshown\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment agencyEditor_AgencyFragment on Agency {\n\t\tid\n\n\t\tcontrolNumber\n\t\ttitle\n\t\tsortTitle\n\n\t\tshown\n\t\tshowToPublic\n\n\t\tchapters {\n\t\t\t...viewChapterButton_ChapterFragment\n\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tfileUrl\n\n\t\t\tshown\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment agencyEditor_QueryFragment on Query {\n\t\t# ... on Query @defer {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t\t# }\n\t}\n"
): (typeof documents)["\n\tfragment agencyEditor_QueryFragment on Query {\n\t\t# ... on Query @defer {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t\t# }\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteChapter($id: ID!) {\n\t\tpermanentlyDeleteChapter(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteChapter($id: ID!) {\n\t\tpermanentlyDeleteChapter(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment chapterEditor_ChapterFragment on Chapter {\n\t\t...viewChapterButton_ChapterFragment\n\n\t\tid\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\tagencyTitle\n\t\teditorsNote\n\t\teditorsEndnote\n\n\t\tidText\n\n\t\theader\n\t\tfooter\n\t\tshown\n\n\t\trules {\n\t\t\t...viewRuleButton_RuleFragment\n\t\t\t...ruleEditor_RuleFragment # @defer\n\t\t\tid\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment chapterEditor_ChapterFragment on Chapter {\n\t\t...viewChapterButton_ChapterFragment\n\n\t\tid\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\tagencyTitle\n\t\teditorsNote\n\t\teditorsEndnote\n\n\t\tidText\n\n\t\theader\n\t\tfooter\n\t\tshown\n\n\t\trules {\n\t\t\t...viewRuleButton_RuleFragment\n\t\t\t...ruleEditor_RuleFragment # @defer\n\t\t\tid\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateChapter(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$editorsNote: String\n\t\t$editorsEndnote: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$header: String\n\t\t$footer: String\n\t\t$shown: Boolean\n\t\t$rules: [ChapterEditorRule!]\n\t) {\n\t\tupdateChapter(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\teditorsNote: $editorsNote\n\t\t\teditorsEndnote: $editorsEndnote\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\theader: $header\n\t\t\tfooter: $footer\n\t\t\tshown: $shown\n\t\t\trules: $rules\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateChapter(\n\t\t$id: ID!\n\t\t$agencyTitle: String\n\t\t$editorsNote: String\n\t\t$editorsEndnote: String\n\t\t$idText: String\n\t\t$title: String\n\t\t$header: String\n\t\t$footer: String\n\t\t$shown: Boolean\n\t\t$rules: [ChapterEditorRule!]\n\t) {\n\t\tupdateChapter(\n\t\t\tid: $id\n\t\t\tagencyTitle: $agencyTitle\n\t\t\teditorsNote: $editorsNote\n\t\t\teditorsEndnote: $editorsEndnote\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\theader: $header\n\t\t\tfooter: $footer\n\t\t\tshown: $shown\n\t\t\trules: $rules\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteRule($id: ID!) {\n\t\tpermanentlyDeleteRule(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteRule($id: ID!) {\n\t\tpermanentlyDeleteRule(id: $id) {\n\t\t\tid\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery editorSelector_Query($controlNumberOrIdText: String!, $skipDocument: Boolean!) {\n\t\t...agencyEditor_QueryFragment\n\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText) @skip(if: $skipDocument) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyEditor_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterEditor_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleEditor_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery editorSelector_Query($controlNumberOrIdText: String!, $skipDocument: Boolean!) {\n\t\t...agencyEditor_QueryFragment\n\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText) @skip(if: $skipDocument) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyEditor_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterEditor_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleEditor_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ruleEditor_RuleFragment on Rule {\n\t\t...viewRuleButton_RuleFragment\n\n\t\tid\n\t\tidText\n\t\tdescription\n\t\ttitle\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tshown\n\t\tpageBreakBefore\n\t}\n"
): (typeof documents)["\n\tfragment ruleEditor_RuleFragment on Rule {\n\t\t...viewRuleButton_RuleFragment\n\n\t\tid\n\t\tidText\n\t\tdescription\n\t\ttitle\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tshown\n\t\tpageBreakBefore\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateRule(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$history: String\n\t\t$penalty: String\n\t\t$editorsNote: String\n\t\t$shown: Boolean\n\t\t$pageBreakBefore: Boolean\n\t) {\n\t\tupdateRule(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\thistory: $history\n\t\t\tpenalty: $penalty\n\t\t\teditorsNote: $editorsNote\n\t\t\tshown: $shown\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateRule(\n\t\t$id: ID!\n\t\t$idText: String\n\t\t$title: String\n\t\t$description: String\n\t\t$author: String\n\t\t$authority: String\n\t\t$history: String\n\t\t$penalty: String\n\t\t$editorsNote: String\n\t\t$shown: Boolean\n\t\t$pageBreakBefore: Boolean\n\t) {\n\t\tupdateRule(\n\t\t\tid: $id\n\t\t\tidText: $idText\n\t\t\ttitle: $title\n\t\t\tdescription: $description\n\t\t\tauthor: $author\n\t\t\tauthority: $authority\n\t\t\thistory: $history\n\t\t\tpenalty: $penalty\n\t\t\teditorsNote: $editorsNote\n\t\t\tshown: $shown\n\t\t\tpageBreakBefore: $pageBreakBefore\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery activityLog($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tactivityLog {\n\t\t\t\tid\n\n\t\t\t\t__typename\n\n\t\t\t\tdate\n\t\t\t\tuser {\n\t\t\t\t\tname\n\t\t\t\t\temail\n\t\t\t\t}\n\n\t\t\t\t... on WithDiffLogEntry {\n\t\t\t\t\twasReset\n\t\t\t\t}\n\n\t\t\t\t... on ChapterDraftUpdatedLogEntry {\n\t\t\t\t\tchapterUpdated\n\t\t\t\t\tupdatedRules\n\t\t\t\t\treorderedRules\n\t\t\t\t\taddedRule\n\t\t\t\t\tdeletedRule\n\t\t\t\t}\n\n\t\t\t\t... on WithFileLogEntry {\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\n\t\t\t\t... on FormChangedLogEntry {\n\t\t\t\t\tform {\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t... on FormUpdatedLogEntry {\n\t\t\t\t\twasNew\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery activityLog($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tactivityLog {\n\t\t\t\tid\n\n\t\t\t\t__typename\n\n\t\t\t\tdate\n\t\t\t\tuser {\n\t\t\t\t\tname\n\t\t\t\t\temail\n\t\t\t\t}\n\n\t\t\t\t... on WithDiffLogEntry {\n\t\t\t\t\twasReset\n\t\t\t\t}\n\n\t\t\t\t... on ChapterDraftUpdatedLogEntry {\n\t\t\t\t\tchapterUpdated\n\t\t\t\t\tupdatedRules\n\t\t\t\t\treorderedRules\n\t\t\t\t\taddedRule\n\t\t\t\t\tdeletedRule\n\t\t\t\t}\n\n\t\t\t\t... on WithFileLogEntry {\n\t\t\t\t\tfileUrl\n\t\t\t\t}\n\n\t\t\t\t... on FormChangedLogEntry {\n\t\t\t\t\tform {\n\t\t\t\t\t\tname\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\t... on FormUpdatedLogEntry {\n\t\t\t\t\twasNew\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery FilingActivityLogDetailsModal($id: ID!) {\n\t\tfilingActivityLogEntry(id: $id) {\n\t\t\t__typename\n\n\t\t\t... on WithDiffLogEntry {\n\t\t\t\tdiff\n\t\t\t}\n\n\t\t\t... on WithFileLogEntry {\n\t\t\t\tfileUrl\n\t\t\t}\n\n\t\t\t... on FormChangedLogEntry {\n\t\t\t\tform {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on FilingRejectedLogEntry {\n\t\t\t\tcomments\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery FilingActivityLogDetailsModal($id: ID!) {\n\t\tfilingActivityLogEntry(id: $id) {\n\t\t\t__typename\n\n\t\t\t... on WithDiffLogEntry {\n\t\t\t\tdiff\n\t\t\t}\n\n\t\t\t... on WithFileLogEntry {\n\t\t\t\tfileUrl\n\t\t\t}\n\n\t\t\t... on FormChangedLogEntry {\n\t\t\t\tform {\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on FilingRejectedLogEntry {\n\t\t\t\tcomments\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: '\n\tfragment draftRow_Filing on Filing {\n\t\tid\n\n\t\tscope\n\t\tintendedAction\n\n\t\tstatus\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n'
): (typeof documents)['\n\tfragment draftRow_Filing on Filing {\n\t\tid\n\n\t\tscope\n\t\tintendedAction\n\n\t\tstatus\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\t__typename\n\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tcertifyingWithChanges: formAnswer(\n\t\t\t\tformKey: "apa-3"\n\t\t\t\tquestion: "certifying-with-changes"\n\t\t\t) {\n\t\t\t\ttext\n\t\t\t}\n\t\t}\n\t}\n']
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery filingChecklist($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...formRow_Filing\n\t\t\t...draftRow_Filing\n\t\t\t...processForms_FilingFragment\n\n\t\t\tstatus\n\t\t\tagencyID\n\t\t\tname\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tstatus\n\n\t\t\tfilingType {\n\t\t\t\thasProposal\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tisProposal\n\t\t\t\tstrikethroughUrl\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\n\t\t\t\t\tcondition\n\t\t\t\t\tsignatureRequired\n\n\t\t\t\t\t...formRow_Form\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\n\t\t\t\t\tvalues {\n\t\t\t\t\t\tkey\n\t\t\t\t\t\ttext\n\t\t\t\t\t}\n\n\t\t\t\t\t...formRow_FormEntry\n\t\t\t\t}\n\n\t\t\t\trejectionComments {\n\t\t\t\t\tcomment\n\t\t\t\t\tcreatedAt\n\t\t\t\t}\n\n\t\t\t\tfileUrl\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery filingChecklist($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\t...formRow_Filing\n\t\t\t...draftRow_Filing\n\t\t\t...processForms_FilingFragment\n\n\t\t\tstatus\n\t\t\tagencyID\n\t\t\tname\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tstatus\n\n\t\t\tfilingType {\n\t\t\t\thasProposal\n\t\t\t}\n\n\t\t\tcurrentSubmission {\n\t\t\t\tisProposal\n\t\t\t\tstrikethroughUrl\n\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tforms {\n\t\t\t\t\tkey\n\n\t\t\t\t\tcondition\n\t\t\t\t\tsignatureRequired\n\n\t\t\t\t\t...formRow_Form\n\t\t\t\t}\n\n\t\t\t\tformEntries {\n\t\t\t\t\tformKey\n\t\t\t\t\tsignatureID\n\n\t\t\t\t\tvalues {\n\t\t\t\t\t\tkey\n\t\t\t\t\t\ttext\n\t\t\t\t\t}\n\n\t\t\t\t\t...formRow_FormEntry\n\t\t\t\t}\n\n\t\t\t\trejectionComments {\n\t\t\t\t\tcomment\n\t\t\t\t\tcreatedAt\n\t\t\t\t}\n\n\t\t\t\tfileUrl\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment formRow_Filing on Filing {\n\t\t...processForms_FilingFragment\n\n\t\tcurrentSubmission {\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment formRow_Filing on Filing {\n\t\t...processForms_FilingFragment\n\n\t\tcurrentSubmission {\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment formRow_Form on Form {\n\t\tkey\n\t\tname\n\t\tdescription\n\n\t\tfields {\n\t\t\tkey\n\t\t\ttext\n\t\t\thelperText\n\t\t\ttype\n\t\t\tcondition\n\n\t\t\toptions {\n\t\t\t\ttext\n\t\t\t\tvalue\n\t\t\t}\n\n\t\t\tdateSpecs {\n\t\t\t\tdefault\n\t\t\t\tfrom\n\t\t\t}\n\n\t\t\ttextSpecs {\n\t\t\t\tdefault\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment formRow_Form on Form {\n\t\tkey\n\t\tname\n\t\tdescription\n\n\t\tfields {\n\t\t\tkey\n\t\t\ttext\n\t\t\thelperText\n\t\t\ttype\n\t\t\tcondition\n\n\t\t\toptions {\n\t\t\t\ttext\n\t\t\t\tvalue\n\t\t\t}\n\n\t\t\tdateSpecs {\n\t\t\t\tdefault\n\t\t\t\tfrom\n\t\t\t}\n\n\t\t\ttextSpecs {\n\t\t\t\tdefault\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment formRow_FormEntry on FormEntry {\n\t\tvalues {\n\t\t\tkey\n\t\t\tnum\n\t\t\ttext\n\t\t\tdate\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment formRow_FormEntry on FormEntry {\n\t\tvalues {\n\t\t\tkey\n\t\t\tnum\n\t\t\ttext\n\t\t\tdate\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation upsertFormEntry(\n\t\t$formKey: String!\n\t\t$filingID: ID!\n\t\t$values: [FormEntryValueInput!]!\n\t) {\n\t\tupsertFormEntry(formKey: $formKey, filingID: $filingID, values: $values) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation upsertFormEntry(\n\t\t$formKey: String!\n\t\t$filingID: ID!\n\t\t$values: [FormEntryValueInput!]!\n\t) {\n\t\tupsertFormEntry(formKey: $formKey, filingID: $filingID, values: $values) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery filingsViewer(\n\t\t$type: PublicationType!\n\t\t$filingTypeKey: String\n\t\t$fromPublication: Boolean!\n\t) {\n\t\tpublication(currentPublication: true) @include(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\tfilings(type: $type) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\t\t}\n\n\t\tfilings(\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tstatus: [certificationEffectiveNotPublished, certificationPublishedAndEffective]\n\t\t) @skip(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_FilingFragment\n\t\t}\n\n\t\t...publicationArchives_QueryFragment\n\t}\n"
): (typeof documents)["\n\tquery filingsViewer(\n\t\t$type: PublicationType!\n\t\t$filingTypeKey: String\n\t\t$fromPublication: Boolean!\n\t) {\n\t\tpublication(currentPublication: true) @include(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\tfilings(type: $type) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\t\t}\n\n\t\tfilings(\n\t\t\tfilingTypeKey: $filingTypeKey\n\t\t\tstatus: [certificationEffectiveNotPublished, certificationPublishedAndEffective]\n\t\t) @skip(if: $fromPublication) {\n\t\t\t...viewCurrentFilings_FilingFragment\n\t\t}\n\n\t\t...publicationArchives_QueryFragment\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation sendPasswordReset($email: String!) {\n\t\tsendPasswordResetLink(email: $email)\n\t}\n"
): (typeof documents)["\n\tmutation sendPasswordReset($email: String!) {\n\t\tsendPasswordResetLink(email: $email)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation login($email: String!, $password: String!) {\n\t\tlogin(email: $email, password: $password) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation login($email: String!, $password: String!) {\n\t\tlogin(email: $email, password: $password) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery pipelineLanding(\n\t\t$status: [FilingStatus!]\n\t\t$agencyID: ID\n\t\t$showEffectiveDate: Boolean!\n\t) {\n\t\t...newDraftButton_QueryFragment\n\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tpublicationDate\n\t\t}\n\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t}\n\n\t\tfilings(agencyID: $agencyID, status: $status) {\n\t\t\t...filingRow_Filing\n\n\t\t\tid\n\t\t\tfilingTypeKey\n\t\t\tintendedAction\n\n\t\t\tagency {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t\tsortTitle\n\t\t\t}\n\n\t\t\tcurrentCodeNumber\n\n\t\t\tcurrentSubmission {\n\t\t\t\tnextPublication {\n\t\t\t\t\tid\n\t\t\t\t\tpublicationDate\n\t\t\t\t}\n\n\t\t\t\tdraft {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery pipelineLanding(\n\t\t$status: [FilingStatus!]\n\t\t$agencyID: ID\n\t\t$showEffectiveDate: Boolean!\n\t) {\n\t\t...newDraftButton_QueryFragment\n\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tpublicationDate\n\t\t}\n\n\t\tfilingTypes {\n\t\t\tkey\n\t\t\tname\n\t\t}\n\n\t\tfilings(agencyID: $agencyID, status: $status) {\n\t\t\t...filingRow_Filing\n\n\t\t\tid\n\t\t\tfilingTypeKey\n\t\t\tintendedAction\n\n\t\t\tagency {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t\tsortTitle\n\t\t\t}\n\n\t\t\tcurrentCodeNumber\n\n\t\t\tcurrentSubmission {\n\t\t\t\tnextPublication {\n\t\t\t\t\tid\n\t\t\t\t\tpublicationDate\n\t\t\t\t}\n\n\t\t\t\tdraft {\n\t\t\t\t\tidText\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment agencyHome_AgencyFragment on Agency {\n\t\tcontrolNumber\n\t\ttitle\n\n\t\tchapters(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...agencyHome_ChapterFragment # @defer\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment agencyHome_AgencyFragment on Agency {\n\t\tcontrolNumber\n\t\ttitle\n\n\t\tchapters(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...agencyHome_ChapterFragment # @defer\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment agencyHome_ChapterFragment on Chapter {\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment agencyHome_ChapterFragment on Chapter {\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment chapterHome_ChapterFragment on Chapter {\n\t\tid\n\n\t\tidText\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...chapterHome_RuleFragment # @defer\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment chapterHome_ChapterFragment on Chapter {\n\t\tid\n\n\t\tidText\n\t\ttitle\n\n\t\tfileUrl\n\n\t\tparent {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\n\t\trules(onlyShown: true) {\n\t\t\tidText\n\t\t\ttitle\n\n\t\t\t...chapterHome_RuleFragment # @defer\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment chapterHome_RuleFragment on Rule {\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n"
): (typeof documents)["\n\tfragment chapterHome_RuleFragment on Rule {\n\t\tdescription\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery publicCode($controlNumberOrIdText: String!) {\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText, onlyShown: true) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyHome_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterHome_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleHome_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery publicCode($controlNumberOrIdText: String!) {\n\t\tdocument(controlNumberOrIdText: $controlNumberOrIdText, onlyShown: true) {\n\t\t\t__typename\n\n\t\t\t... on Agency {\n\t\t\t\t...agencyHome_AgencyFragment\n\t\t\t}\n\n\t\t\t... on Chapter {\n\t\t\t\t...chapterHome_ChapterFragment\n\t\t\t}\n\n\t\t\t... on Rule {\n\t\t\t\t...ruleHome_RuleFragment\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment ruleHome_RuleFragment on Rule {\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tfileUrl\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment ruleHome_RuleFragment on Rule {\n\t\tidText\n\t\ttitle\n\t\tdescription\n\n\t\tfileUrl\n\n\t\tauthor\n\t\tauthority\n\t\thistory\n\t\tpenalty\n\t\teditorsNote\n\n\t\tparent {\n\t\t\tidText\n\t\t\ttitle\n\t\t\tparent {\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\t\t}\n\n\t\tfilings {\n\t\t\t...filingHistory_FilingsFragment\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery requestAccountQuery {\n\t\tagencies {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery requestAccountQuery {\n\t\tagencies {\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation requestAccount(\n\t\t$name: String!\n\t\t$email: String!\n\t\t$password: String!\n\t\t$controlNumbers: [String!]!\n\t) {\n\t\trequestAccount(\n\t\t\tname: $name\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t\tagencyControlNumbers: $controlNumbers\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation requestAccount(\n\t\t$name: String!\n\t\t$email: String!\n\t\t$password: String!\n\t\t$controlNumbers: [String!]!\n\t) {\n\t\trequestAccount(\n\t\t\tname: $name\n\t\t\temail: $email\n\t\t\tpassword: $password\n\t\t\tagencyControlNumbers: $controlNumbers\n\t\t) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery userEmailForToken($token: String!) {\n\t\tuserEmailForToken(token: $token)\n\t}\n"
): (typeof documents)["\n\tquery userEmailForToken($token: String!) {\n\t\tuserEmailForToken(token: $token)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation resetPassword($password: String!, $token: String!) {\n\t\tresetPassword(password: $password, token: $token)\n\t}\n"
): (typeof documents)["\n\tmutation resetPassword($password: String!, $token: String!) {\n\t\tresetPassword(password: $password, token: $token)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery resourcesAndAnnouncementsQuery {\n\t\t...resourceList_QueryFragment\n\t\t...announcementList_QueryFragment\n\t}\n"
): (typeof documents)["\n\tquery resourcesAndAnnouncementsQuery {\n\t\t...resourceList_QueryFragment\n\t\t...announcementList_QueryFragment\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery allAgenciesQuery {\n\t\t...adminCodeSearch_QueryFragment\n\t\t...publicationArchiveSearch_QueryFragment\n\t}\n"
): (typeof documents)["\n\tquery allAgenciesQuery {\n\t\t...adminCodeSearch_QueryFragment\n\t\t...publicationArchiveSearch_QueryFragment\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery strikethrough($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tstrikethroughUrl\n\t\t\t\tstrikethroughViewed\n\n\t\t\t\t# Disabled per INS-512\n\t\t\t\t# overrideStrikethrough {\n\t\t\t\t# \tfilename\n\t\t\t\t# }\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery strikethrough($id: ID!) {\n\t\tfiling(id: $id) {\n\t\t\tname\n\n\t\t\tstatus\n\n\t\t\tintendedAction\n\t\t\tscope\n\n\t\t\tagencyID\n\n\t\t\tcurrentSubmission {\n\t\t\t\tcurrentCode {\n\t\t\t\t\t__typename\n\n\t\t\t\t\tidText\n\t\t\t\t}\n\n\t\t\t\tstrikethroughUrl\n\t\t\t\tstrikethroughViewed\n\n\t\t\t\t# Disabled per INS-512\n\t\t\t\t# overrideStrikethrough {\n\t\t\t\t# \tfilename\n\t\t\t\t# }\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\t# Disabled per INS-512\n\t# mutation updateStrikethrough($filingID: ID! , $override: File) {\n\tmutation updateStrikethrough($filingID: ID!) {\n\t\tapproveStrikethrough(id: $filingID, viewed: true) {\n\t\t\t__typename\n\t\t}\n\n\t\t# Disabled per INS-512\n\t\t# overrideStrikethroughForFiling(id: $filingID, override: $override) {\n\t\t# \t__typename\n\t\t# }\n\t}\n"
): (typeof documents)["\n\t# Disabled per INS-512\n\t# mutation updateStrikethrough($filingID: ID! , $override: File) {\n\tmutation updateStrikethrough($filingID: ID!) {\n\t\tapproveStrikethrough(id: $filingID, viewed: true) {\n\t\t\t__typename\n\t\t}\n\n\t\t# Disabled per INS-512\n\t\t# overrideStrikethroughForFiling(id: $filingID, override: $override) {\n\t\t# \t__typename\n\t\t# }\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery upcomingPublication {\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tid\n\t\t\tpublicationDate\n\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\taamFilings: filings(type: aam) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tproposals: filings(type: proposal) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tapproved\n\n\t\t\teditorsNote\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery upcomingPublication {\n\t\tupcomingSubmission: publication(upcomingSubmission: true) {\n\t\t\tpublicationDate\n\t\t\tsubmissionDeadline\n\t\t}\n\n\t\tupcomingPublication: publication(upcomingPublication: true) {\n\t\t\tid\n\t\t\tpublicationDate\n\n\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\taamFilings: filings(type: aam) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tproposals: filings(type: proposal) {\n\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t}\n\n\t\t\tapproved\n\n\t\t\teditorsNote\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation setPublicationApproved($id: String!, $approved: Boolean!) {\n\t\tsetPublicationApproved(id: $id, approved: $approved) {\n\t\t\tapproved\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation setPublicationApproved($id: String!, $approved: Boolean!) {\n\t\tsetPublicationApproved(id: $id, approved: $approved) {\n\t\t\tapproved\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updatePublicationEditorsNote($id: String!, $editorsNote: String!) {\n\t\tupdatePublicationEditorsNote(id: $id, editorsNote: $editorsNote) {\n\t\t\teditorsNote\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updatePublicationEditorsNote($id: String!, $editorsNote: String!) {\n\t\tupdatePublicationEditorsNote(id: $id, editorsNote: $editorsNote) {\n\t\t\teditorsNote\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery userProfile($id: ID!) {\n\t\t...userProfile_QueryFragment # @defer\n\t\tuser(id: $id) {\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tsubscribedToEmails\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery userProfile($id: ID!) {\n\t\t...userProfile_QueryFragment # @defer\n\t\tuser(id: $id) {\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tpendingAgencies {\n\t\t\t\tid\n\t\t\t\tcontrolNumber\n\t\t\t\ttitle\n\t\t\t}\n\n\t\t\tsubscribedToEmails\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment userProfile_QueryFragment on Query {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment userProfile_QueryFragment on Query {\n\t\tagencies {\n\t\t\tid\n\t\t\tcontrolNumber\n\t\t\ttitle\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation updateUser(\n\t\t$id: ID!\n\t\t$email: String\n\t\t$isAdmin: Boolean\n\t\t$isActive: Boolean\n\t\t$name: String\n\t\t$pendingAgencyIDs: [String!]\n\t\t$subscribedToEmails: Boolean\n\t) {\n\t\tupdateUser(\n\t\t\tid: $id\n\t\t\temail: $email\n\t\t\tisAdmin: $isAdmin\n\t\t\tisActive: $isActive\n\t\t\tname: $name\n\t\t\tpendingAgencyIDs: $pendingAgencyIDs\n\t\t\tsubscribedToEmails: $subscribedToEmails\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation updateUser(\n\t\t$id: ID!\n\t\t$email: String\n\t\t$isAdmin: Boolean\n\t\t$isActive: Boolean\n\t\t$name: String\n\t\t$pendingAgencyIDs: [String!]\n\t\t$subscribedToEmails: Boolean\n\t) {\n\t\tupdateUser(\n\t\t\tid: $id\n\t\t\temail: $email\n\t\t\tisAdmin: $isAdmin\n\t\t\tisActive: $isActive\n\t\t\tname: $name\n\t\t\tpendingAgencyIDs: $pendingAgencyIDs\n\t\t\tsubscribedToEmails: $subscribedToEmails\n\t\t) {\n\t\t\tid\n\t\t\tname\n\t\t\temail\n\t\t\tisAdmin\n\t\t\tisActive\n\n\t\t\tagencies {\n\t\t\t\tid\n\t\t\t\ttitle\n\t\t\t\tcontrolNumber\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation approveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation approveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation disapproveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tdisapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation disapproveUser($id: ID!, $agencyIDs: [ID!]!) {\n\t\tdisapproveUser(id: $id, agencyIDs: $agencyIDs) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation requestAgency($agency: ID!) {\n\t\trequestAgency(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation requestAgency($agency: ID!) {\n\t\trequestAgency(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation cancelAgencyRequest($agency: ID!) {\n\t\tcancelAgencyRequest(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation cancelAgencyRequest($agency: ID!) {\n\t\tcancelAgencyRequest(agency: $agency) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteUser($id: ID!) {\n\t\tpermanentlyDeleteUser(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteUser($id: ID!) {\n\t\tpermanentlyDeleteUser(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery viewPublication($id: ID!, $type: PublicationType!) {\n\t\tpublicationOrHistoricalPublication(id: $id, type: $type) {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\t... on Publication {\n\t\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\t\tfilings(type: $type) {\n\t\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on HistoricalPublication {\n\t\t\t\tcontents(type: $type) {\n\t\t\t\t\tcontent\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery viewPublication($id: ID!, $type: PublicationType!) {\n\t\tpublicationOrHistoricalPublication(id: $id, type: $type) {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\t... on Publication {\n\t\t\t\t...viewCurrentFilings_PublicationFragment\n\n\t\t\t\tfilings(type: $type) {\n\t\t\t\t\t...viewCurrentFilings_FilingFragment\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t... on HistoricalPublication {\n\t\t\t\tcontents(type: $type) {\n\t\t\t\t\tcontent\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery announcementsMetaAndResources {\n\t\tannouncements {\n\t\t\t__typename\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\n\t\tresources {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\ttitle\n\n\t\t\tfileUrl\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery announcementsMetaAndResources {\n\t\tannouncements {\n\t\t\t__typename\n\t\t\tid\n\t\t\ttitle\n\t\t}\n\n\t\tresources {\n\t\t\t__typename\n\n\t\t\tid\n\n\t\t\ttitle\n\n\t\t\tfileUrl\n\t\t\tfile {\n\t\t\t\tfilename\n\t\t\t}\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tquery announcementsContents {\n\t\tannouncements {\n\t\t\tid\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n"
): (typeof documents)["\n\tquery announcementsContents {\n\t\tannouncements {\n\t\t\tid\n\t\t\tdescription\n\t\t\tcontent\n\t\t\tupdatedAt\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation sortAnnouncements($announcements: [ID!]!) {\n\t\tsortAnnouncements(announcements: $announcements)\n\t}\n"
): (typeof documents)["\n\tmutation sortAnnouncements($announcements: [ID!]!) {\n\t\tsortAnnouncements(announcements: $announcements)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation sortResources($resources: [ID!]!) {\n\t\tsortResources(resources: $resources)\n\t}\n"
): (typeof documents)["\n\tmutation sortResources($resources: [ID!]!) {\n\t\tsortResources(resources: $resources)\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteAnnouncement($id: ID!) {\n\t\tpermanentlyDeleteAnnouncement(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteAnnouncement($id: ID!) {\n\t\tpermanentlyDeleteAnnouncement(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tmutation deleteResource($id: ID!) {\n\t\tpermanentlyDeleteResource(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"
): (typeof documents)["\n\tmutation deleteResource($id: ID!) {\n\t\tpermanentlyDeleteResource(id: $id) {\n\t\t\t__typename\n\t\t}\n\t}\n"]
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
	source: "\n\tfragment processForms_FilingFragment on Filing {\n\t\tstatus\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tforms {\n\t\t\t\tkey\n\n\t\t\t\tdependsOnStrikethrough\n\t\t\t\tdependsOnStrikethroughCondition\n\t\t\t\tsignatureRequired\n\n\t\t\t\tfields {\n\t\t\t\t\tkey\n\t\t\t\t\tcondition\n\t\t\t\t\ttype\n\t\t\t\t\toptional\n\n\t\t\t\t\tdateSpecs {\n\t\t\t\t\t\tmax\n\t\t\t\t\t\tmin\n\t\t\t\t\t\tfrom\n\t\t\t\t\t}\n\n\t\t\t\t\tfileSpecs {\n\t\t\t\t\t\ttype\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tformKey\n\n\t\t\t\tvalues {\n\t\t\t\t\tkey\n\t\t\t\t\tnum\n\t\t\t\t\ttext\n\t\t\t\t\tdate\n\t\t\t\t\tfile {\n\t\t\t\t\t\t__typename\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\tsignatureID\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n"
): (typeof documents)["\n\tfragment processForms_FilingFragment on Filing {\n\t\tstatus\n\t\tintendedAction\n\n\t\tcurrentSubmission {\n\t\t\tdraft {\n\t\t\t\tisValid\n\t\t\t}\n\n\t\t\tstrikethroughViewed\n\n\t\t\tforms {\n\t\t\t\tkey\n\n\t\t\t\tdependsOnStrikethrough\n\t\t\t\tdependsOnStrikethroughCondition\n\t\t\t\tsignatureRequired\n\n\t\t\t\tfields {\n\t\t\t\t\tkey\n\t\t\t\t\tcondition\n\t\t\t\t\ttype\n\t\t\t\t\toptional\n\n\t\t\t\t\tdateSpecs {\n\t\t\t\t\t\tmax\n\t\t\t\t\t\tmin\n\t\t\t\t\t\tfrom\n\t\t\t\t\t}\n\n\t\t\t\t\tfileSpecs {\n\t\t\t\t\t\ttype\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\n\t\t\tformEntries {\n\t\t\t\tformKey\n\n\t\t\t\tvalues {\n\t\t\t\t\tkey\n\t\t\t\t\tnum\n\t\t\t\t\ttext\n\t\t\t\t\tdate\n\t\t\t\t\tfile {\n\t\t\t\t\t\t__typename\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\tsignatureID\n\t\t\t}\n\n\t\t\tnextPublication {\n\t\t\t\tpublicationDate\n\t\t\t}\n\t\t}\n\t}\n"]

export function graphql(source: string) {
	return (documents as any)[source] ?? {}
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
	TDocumentNode extends DocumentNode<infer TType, any> ? TType : never
